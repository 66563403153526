import React from 'react';
import AppBar from './AppBar';
import { useRouter } from 'next/router';
import YellowSectionDetails from './YellowSectionDetails';
import HighlighYellowSection from 'molecules/HighlighYellowSection';


const AppBarWrapper = ({ handlerOpenStoreModal, width }) => {
    const router = useRouter();
    const onClickHandler = () => {
        router.asPath == '/' ? router.push('/practitioner') : router.push('/')
    }

    return (
        <>
            {width > 768 ? (
                <AppBar
                    handlerOpenStoreModal={handlerOpenStoreModal}
                    width={width}
                    className={`${router.asPath === '/' || router.asPath === '/practitioner' ? '' : 'hidden lg:flex'} lg:hidden w-full bg-basic-white h-9 flex items-center px-5 justify-between`}
                />
            ) :
                (
                    (router.asPath === '/' || router.asPath === '/practitioner') &&
                    <HighlighYellowSection
                        className='bg-other-yellow-400 py-[6px] sm:py-3 md:hidden'
                        title={`${router.asPath == '/' ? 'Are you a Doctor/Chemist? ' : 'Not a Doctor/Chemist?'}`}
                        onBtnText='Click Here'
                        onSellClick={onClickHandler}
                    />
                )
            }

        </>
    );
}

export default AppBarWrapper;
