import React from 'react'
import IconChevronDown from 'atoms/SvgIcons/IconChevronDown.svg';
import IconChevronDownThin from 'atoms/SvgIcons/IconChevronDownThin.svg';
import IconLocation from 'atoms/SvgIcons/IconLocation.svg';
import Text from 'atoms/Text'
import { getAuth } from './../services/identity.service';
import IconLocationRoundFilled from 'atoms/SvgIcons/IconLocationRoundFilled.svg';

const PincodeChange = (props) => {
    const { uiFor, onOpen, currentPincode, isVerifiedUser } = props
    const auth = getAuth();
    return (
        uiFor == 'mobile'
            ? (
                <div className={`${isVerifiedUser ? 'flex lg:hidden' : 'hidden'} items-center px-5 py-3 bg-basic-white`}>
                    <IconLocation className='w-4.5 text-gray-500 mr-1' />
                    <Text type='bodySmall' fontWeight={400} color='text-gray-500'>Deliver to -</Text>
                    <button className='flex items-center ml-1' onClick={onOpen} data-automation='pincode-change'>
                        <Text type='bodySmall' fontWeight={700} color='text-gray-500'>{currentPincode}</Text>
                        <IconChevronDownThin className='w-2.5 text-gray-400 ml-2' />
                    </button>
                </div>
            )
            : (
                <button onClick={onOpen} data-automation='pincode-change' className={`${isVerifiedUser ? 'hidden lg:flex' : 'hidden'} items-center ml-8 bg-basic-white`}>
                    <IconLocationRoundFilled className='w-6 text-gray-500 mr-1 fillno' />
                    <div className=''>
                        <Text className='text-12-18 text-left' fontWeight={400} color='text-gray-500'>Deliver to -</Text>
                        <div className='flex items-center'>
                            <Text className='text-14-20' fontWeight={600} color='text-gray-600'>{currentPincode}</Text>
                            <IconChevronDownThin className='w-2.5 text-gray-600 ml-1' />
                        </div>

                    </div>
                </button>
            )
    )
}

export default PincodeChange