import { Svg } from "atoms/SvgIcon";

const IconHamburger = ({ ...rest }) => {
    return <Svg viewBox='0 0 24 24' type='IconHamburger' {...rest} >
        <path d="M2 5.88C2 5.39357 2.446 5 2.995 5H11.005C11.2689 5 11.522 5.09271 11.7086 5.25775C11.8952 5.42278 12 5.64661 12 5.88C12 6.11339 11.8952 6.33722 11.7086 6.50225C11.522 6.66729 11.2689 6.76 11.005 6.76H2.995C2.73111 6.76 2.47803 6.66729 2.29143 6.50225C2.10483 6.33722 2 6.11339 2 5.88Z" />
        <path d="M2 11.8849C2 11.3985 2.42816 11.0049 2.9552 11.0049H20.2448C20.4981 11.0049 20.7411 11.0976 20.9202 11.2626C21.0994 11.4277 21.2 11.6515 21.2 11.8849C21.2 12.1183 21.0994 12.3421 20.9202 12.5071C20.7411 12.6722 20.4981 12.7649 20.2448 12.7649H2.9552C2.70187 12.7649 2.45891 12.6722 2.27977 12.5071C2.10064 12.3421 2 12.1183 2 11.8849Z" />
        <path d="M2.995 17.0098C2.73111 17.0098 2.47803 17.1025 2.29143 17.2675C2.10483 17.4325 2 17.6564 2 17.8898C2 18.1232 2.10483 18.347 2.29143 18.512C2.47803 18.6771 2.73111 18.7698 2.995 18.7698H15.005C15.2689 18.7698 15.522 18.6771 15.7086 18.512C15.8952 18.347 16 18.1232 16 17.8898C16 17.6564 15.8952 17.4325 15.7086 17.2675C15.522 17.1025 15.2689 17.0098 15.005 17.0098H2.995Z" />
    </Svg>
};

export default IconHamburger;