import * as HttpService from "./http.service";
import { getAuth } from "./identity.service";

import { ADD_PUSH_NOTIFICATION, GET_NOTIFICATIONS, GET_NOTIFICATIONS_COUNT, READ_NOTIFICATION, UPDATE_NOTIFICATION } from "./url.service";

export const addPushNotification = (body) => {
    const token = process.env.NEXT_PUBLIC_API_TOKEN
    return HttpService.postWithToken(ADD_PUSH_NOTIFICATION(), body, token);
}

export const getPushNotification = (userId, pageNo, pageSize = 10) => {
    return HttpService.getWithAuth(GET_NOTIFICATIONS({ userId, pageNo, pageSize }));
}

export const getNotificationCount = (userId) => {
    return HttpService.getWithAuth(GET_NOTIFICATIONS_COUNT({ userId }));
}

export const updateNotification = (notificationId, data) => {
    return HttpService.putWithAuth(UPDATE_NOTIFICATION(notificationId), data);
}

export const readNotification = (userId, notificationId) => {
    return HttpService.putWithAuth(READ_NOTIFICATION(), { userId, notificationId });
}
