import React, { useRef, useState } from 'react'
import LinkNext from 'next/link';
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu,
    useHover,
    ControlledMenu
} from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg';
import LinkAnchor from 'atoms/LinkAnchor';
import IconChevronDown from 'atoms/SvgIcons/IconChevronDown.svg';
import Text from 'atoms/Text';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { handleAnalyticEventHandleNavBarNavigation } from 'utils/ga-analytic';

const NestedDropdown = ({ className = '', list, children, dataAutomation, href, onClick, mainMenuClass }) => {
    const [isOpen, setIsOpen] = useState(false);
    const router = useRouter()


    return (
        <div onMouseLeave={() => setIsOpen(false)}>
            <Link href={href || 'javascript:void(0);'}>
                <div className='flex gap-2 pb-0.5 mt-0.5 cursor-pointer' data-automation={dataAutomation} onClick={(e) => { onClick?.(e); setIsOpen(true); }} onMouseEnter={() => setIsOpen(true)}>
                    <Text type='bodySmall' className={`${isOpen ? 'text-primary1-500' : 'text-gray-600'} text-14-20 font-semibold transition-all duration-300`}>{children}</Text>
                    <IconChevronDown className={`${isOpen ? '-rotate-180 text-primary1-500' : 'text-gray-800'} flex-shrink-0 w-3 transform transition-all duration-300`} />
                </div>
            </Link>

            <ControlledMenu
                state={isOpen ? 'open' : 'closed'}
                onClose={() => setIsOpen(false)}
                onMouseLeave={() => setIsOpen(false)}
                transition className={`${className} nestedDropdown`}>

                {list?.map(({ label, redirection, subMenuList, id, menuDivider, menuHeader, multipleSubMenu, onClick }) => {

                    if (menuDivider) return <div className='w-full h-[1px] bg-gray-200'></div>
                    if (menuHeader) return <div className='pt-2.5 pb-2 px-5 font-semibold text-14-16'>{menuHeader}</div>

                    if (multipleSubMenu) return MultipleSubMenuListing({ label, redirection, subMenuList, dataAutomation, mainMenuClass, router })

                    return subMenuList
                        ? SubMenuListing({ label, redirection, subMenuList, mainMenuClass, router })
                        : <LinkAnchor className='single-nested-link' id={id} href={redirection} key={label} onClick={(e) => { onClick && onClick(e); handleAnalyticEventHandleNavBarNavigation({ router }) }}><MenuItem className={`px-5 ${mainMenuClass}`} key={label}>{label}</MenuItem></LinkAnchor>
                })}
            </ControlledMenu>
        </div>
    )
}

export default NestedDropdown

function SubMenuListing({ label, redirection, subMenuList, mainMenuClass, router }) {
    return subMenuList?.length
        ? <SubMenu className={`${mainMenuClass}`} label={<LinkAnchor href={redirection} key={label}>{label} <IconAngleRight className={`absolute right-2 w-5 fill-gray-500 ${mainMenuClass ? 'top-1' : 'top-2'}`} onClick={() => handleAnalyticEventHandleNavBarNavigation({ router })} /></LinkAnchor>}>
            {subMenuList?.map(({ label, redirection, subMenuList, onClick }) => (
                subMenuList
                    ? SubMenuListing({ label, redirection, subMenuList, mainMenuClass, router })
                    : <LinkAnchor href={redirection} key={label} onClick={(e) => { onClick && onClick(e); handleAnalyticEventHandleNavBarNavigation({ router })}}><MenuItem>{label}</MenuItem></LinkAnchor>
            ))}
        </SubMenu>
        : null

}


function MultipleSubMenuListing({ label, redirection, subMenuList, dataAutomation, mainMenuClass, router }) {
    return subMenuList?.length
        ? <SubMenu className={`${mainMenuClass ? mainMenuClass : 'menu--default labelDropdown'} `} style={{ transform: 'translate' }} data-automation={dataAutomation} label={<LinkAnchor href={redirection || 'javascript:void(0)'} className='' key={label}>{label} <IconAngleRight className={`absolute right-2 w-5 fill-gray-500 ${mainMenuClass ? 'top-1' : 'top-1'}`} /></LinkAnchor>}>

            <div className='labelDropdown--content w-[calc(100vw_-_430px)] lg:max-w-[1100px] grid grid-cols-5 gap-x-2 px-2'>
                {label == 'Brand' && <div className='w-full pt-3 col-span-5'>
                    <Text className={"text-18-20 mb-4"} type={"bodySmall"} fontWeight={600} color='text-gray-900'>Featured Brands</Text>
                </div>}

                {label == 'Brand' ? subMenuList?.map(({ id, name, }) => (
                    <MenuItem key={name} className="brandMenu" onClick={() => handleAnalyticEventHandleNavBarNavigation({ router })}>
                        <LinkNext href={{ pathname: `/medicines/${name?.replaceAll(" ", "-").toLowerCase()}`, query: { id: `${id}` } }} as={`/medicines/${name?.replaceAll(" ", "-").toLowerCase()}`}   >
                            <a data-automation={`${dataAutomation}-link`}>{name}</a>
                        </LinkNext>
                    </MenuItem>
                )) :
                    subMenuList?.map(({ id, name, }) => (
                        <MenuItem key={name} onClick={() => handleAnalyticEventHandleNavBarNavigation({ router })}>
                            <LinkNext href={`/medicines/category/${id}`} >
                                <a data-automation={`${dataAutomation}-link`}>{name}</a>
                            </LinkNext>
                        </MenuItem>
                    ))
                }
            </div>
        </SubMenu>
        : null

}