import React from 'react'
import Text from 'atoms/Text'
import Button from 'atoms/Button'
import Container from 'atoms/Container'

const HighlighYellowSection = ({ className = '', title, onSellClick, onBtnText }) => {
    return (
        <div className={`${className}`}>
            <Container type='static' className='px-4'>
                <Text type='subtitle' className={`text-gray-900 text-center sm:flex justify-center items-center flex-wrap text-sm md:text-base`}>
                    {title}
                    <Button fontWeight={600} variant='Link' className='pl-1 text-sm md:text-base inline-block' onClick={onSellClick}>{onBtnText}</Button>
                </Text>
            </Container>
        </div>
    )
}

export default HighlighYellowSection