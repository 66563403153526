import { Svg } from "atoms/SvgIcon";

const IconRefund = ({ ...rest }) => {
    return <Svg viewBox='0 0 16 17' type='IconRefund' {...rest} >
        <g clip-path="url(#clip0_11620_35687)">
<path d="M7.52611 16.9469C7.22862 16.907 6.92806 16.8764 6.63364 16.8212C6.34228 16.769 6.05399 16.6985 5.76877 16.6218C5.48048 16.5451 5.33021 16.2599 5.40995 15.9747C5.48662 15.6956 5.77184 15.5361 6.06013 15.6189C6.67351 15.7907 7.29915 15.8827 7.93707 15.8827C11.0224 15.8827 13.7243 13.8217 14.5462 10.8407C15.5399 7.24019 13.4667 3.48017 9.90602 2.43129C6.17054 1.32721 2.28785 3.49857 1.31564 7.28006C0.735996 9.53422 1.22057 11.589 2.67427 13.4077C2.70494 13.4476 2.74175 13.4844 2.80922 13.558C2.80922 13.4415 2.80922 13.3617 2.80922 13.282C2.80922 12.9599 3.0331 12.7269 3.33672 12.7269C3.64035 12.7269 3.87036 12.9599 3.87343 13.2789C3.87343 13.7696 3.87343 14.2572 3.87343 14.7479C3.87343 15.1098 3.64035 15.346 3.28152 15.3491C2.78775 15.3521 2.29398 15.3521 1.80021 15.3491C1.48739 15.3491 1.2543 15.1068 1.25737 14.8032C1.2635 14.5057 1.49352 14.2848 1.80328 14.2818C1.86461 14.2818 1.92595 14.2818 2.01183 14.2818C1.97809 14.2327 1.95969 14.199 1.93515 14.1683C0.521313 12.4938 -0.119668 10.5524 0.0183423 8.36574C0.239159 4.92775 2.75401 1.96206 6.11533 1.1708C6.52323 1.07572 6.94339 1.03585 7.36049 0.97145C7.4065 0.965316 7.4525 0.953048 7.49544 0.943848C7.83893 0.943848 8.18242 0.943848 8.52591 0.943848C8.56578 0.953048 8.60565 0.965316 8.64552 0.97145C8.94915 1.01439 9.25583 1.03279 9.55332 1.10026C12.7858 1.84245 14.856 3.8206 15.7607 7.0163C15.8435 7.31073 15.8865 7.61435 15.9478 7.91184V9.97279C15.9386 9.99119 15.9263 10.0096 15.9233 10.028C15.8374 10.4298 15.7883 10.8407 15.6595 11.2302C14.6413 14.3063 12.5466 16.1771 9.37238 16.8212C9.08409 16.8794 8.78967 16.904 8.49524 16.9438H7.52917L7.52611 16.9469Z" fill="#4B4B63"/>
<path d="M7.96503 12.2271C6.74747 12.2271 5.53298 12.2271 4.31542 12.2271C3.32788 12.2271 2.63783 11.5401 2.63477 10.5495C2.63477 9.48218 2.63477 8.4149 2.63477 7.34762C2.63477 6.36621 3.32788 5.6731 4.30316 5.6731C6.74134 5.6731 9.17952 5.6731 11.6146 5.6731C12.593 5.6731 13.283 6.36621 13.2861 7.34455C13.2861 8.41797 13.2892 9.49138 13.2861 10.5617C13.283 11.5401 12.5899 12.2271 11.6116 12.2271C10.394 12.2271 9.17952 12.2271 7.96196 12.2271H7.96503ZM7.96503 6.73424C6.74747 6.73424 5.53298 6.73424 4.31542 6.73424C3.88299 6.73424 3.68364 6.93359 3.68364 7.36296C3.68364 8.4149 3.68364 9.46685 3.68364 10.5188C3.68364 10.9635 3.88606 11.1628 4.33689 11.1628C6.75361 11.1628 9.17032 11.1628 11.587 11.1628C12.0225 11.1628 12.228 10.9604 12.228 10.5249C12.228 9.47911 12.228 8.43024 12.228 7.38442C12.228 6.93666 12.0256 6.73424 11.5809 6.73424C10.3756 6.73424 9.17032 6.73424 7.96503 6.73424Z" fill="#4B4B63"/>
<path d="M7.95491 10.6782C7.00111 10.6752 6.23132 9.89925 6.23438 8.94544C6.23438 7.99164 7.00724 7.21265 7.95798 7.21265C8.92712 7.21265 9.70304 7.9947 9.69691 8.96078C9.68771 9.91458 8.91485 10.6813 7.95798 10.6782H7.95491ZM7.97025 8.28606C7.59915 8.28299 7.30473 8.57128 7.30473 8.94238C7.30473 9.31347 7.59302 9.60483 7.96411 9.60483C8.32601 9.60483 8.62043 9.31654 8.6235 8.95464C8.6235 8.58048 8.34134 8.28606 7.97025 8.28299V8.28606Z" fill="#4B4B63"/>
</g>
<defs>
<clipPath id="clip0_11620_35687">
<rect width="15.9479" height="16" fill="white" transform="translate(0 0.947021)"/>
</clipPath>
</defs>
    </Svg>
};

export default IconRefund;