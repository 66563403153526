import React, { useEffect, useRef, useState } from 'react';

import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Modal from '../../atoms/Modal';
import Paragraph from '../../atoms/Paragraph';
import { useRouter } from 'next/router';
import ImageNext from 'atoms/ImageNext';
import * as moengage from "services/moengage.service";
import mo_engage_verification from "../../assets/mo_engage/verification";

import { getAuth, getRedirectUrlForUserType, removeRedirectUrlForUserType, setAuth } from './../../services/identity.service';
const ModalVerifyDocumentPopUps = ({ showModalFor, reason, isModalOpen, handlerModalClose }) => {
    const auth = getAuth();

    const reasonVaidyaTool = auth?.user?.tbl_doctor_profile?.reason

    const router = useRouter()
    const entity = mo_engage_verification.entity;


    const textLabels = {
        messageText: {
            verifyDocumentsPending: 'Dear Patron, Please provide additional information to validate your profile and continue to participate in engagement activities and purchase medicine.',
            verifyDocumentsUnderReview: 'Your documents were submitted successfully,and we are reviewing it.',
            verifyDocumentsRejected: `Dear Patron, Your document verification is not approved, due to "${reason || reasonVaidyaTool}". Click here to re-upload your documents`,
            verifyDocumentsPendingUserType: 'This content is only available to verified doctors/students. Please complete your verification process to access this page.',
            verifyDocumentsUnderReviewUserType: 'Your documents are presently under review. Please allow up to 24 hours for us to review your documents. Once your account is verified, you will be able to access this page. Thank you for your patience. In the meantime, check interesting content on our feed page.',
            verifyDocumentsRejectedUserType: `Your document verification is not approved, due to "${reason || reasonVaidyaTool}". Click here to re-upload your documents.`,
            verifyDocumentsChemistUser: 'We apologize, you do not have access to this content. If you have any questions or require further assistance please contact customer support.'
        },
        headingText: {
            verifyDocumentsPending: 'Attention!',
            verifyDocumentsUnderReview: 'Documents under review',
            verifyDocumentsRejected: 'Verification Rejected',
            verifyDocumentsPendingUserType: 'Attention!',
            verifyDocumentsUnderReviewUserType: 'Attention!',
            verifyDocumentsRejectedUserType: 'Attention!',
            verifyDocumentsChemistUser: 'Attention!',
        },
        okButtonText: {
            verifyDocumentsPending: 'Continue with verification',
            verifyDocumentsUnderReview: 'Okay',
            verifyDocumentsRejected: 'Re-upload',
            verifyDocumentsPendingUserType: 'Continue with verification',
            verifyDocumentsUnderReviewUserType: 'Okay',
            verifyDocumentsRejectedUserType: 'Re-upload',
            verifyDocumentsChemistUser: 'Okay',
        },
        isShowCloseBtn: {
            verifyDocumentsPending: false,
        },
        isNotification: {
            verifyDocumentsUnderReview: true,
        },
        redirectTo: {
            verifyDocumentsPending: '/profile-verification',
            verifyDocumentsRejected: '/profile-verification',
            verifyDocumentsPendingUserType: '/profile-verification',
            verifyDocumentsUnderReviewUserType: '/feeds',
            verifyDocumentsRejectedUserType: '/profile-verification',
            verifyDocumentsChemistUser: '/contact-us',
        },
        automation: {
            verifyDocumentsPending: 'verify-pending',
            verifyDocumentsUnderReview: 'verify-under-review',
            verifyDocumentsRejected: 'verify-rejected',
            verifyDocumentsPendingUserType: 'verify-pending',
            verifyDocumentsUnderReviewUserType: 'verify-under-review',
            verifyDocumentsRejectedUserType: 'verify-rejected',
        },
        paragraph: {
            verifyDocumentsUnderReview: 'mt-2 mb-4',
        }
    };

    const handleVerifyDocumentsStates = () => {
        const localShowModalFor = textLabels.redirectTo[showModalFor]
        if (localShowModalFor) {
            if (localShowModalFor !== 'Okay') {
                router.push(textLabels.redirectTo[showModalFor]);
            }
        }
        else if (router.pathname == '/my-medicine-list') {
            handlerModalClose();
        }
        else if (router.pathname !== '/medicines') {
            moengage.trackEvent(entity.verification_upload_documents_success.event_name, {})
            router.push('/medicines')
        }

        handlerModalClose();
    }

    useEffect(() => {
        setTimeout(() => {
            if (getRedirectUrlForUserType()) {
                removeRedirectUrlForUserType()
            }
        }, 5000);
    }, [])


    const handleVerifyDocumentsClose = () => {
        if (router.pathname === '/community') {
            router.push('/medicines');
        }
        handlerModalClose();
    }

    return (
        <Modal className='p-6 max-w-[450px]' type=" withoutCloseIcon1" isShowCloseBtn={textLabels.isShowCloseBtn[showModalFor]} isModalOpen={isModalOpen} dataAutomation='profile-verify'>
            {showModalFor == 'verifyDocumentsUnderReview' && <ImageNext containerClass='text-center' src='/images/research.svg' alt="Verification Under Review" width={80} height={80} />}
            {textLabels.headingText[showModalFor] && <Heading className='text-center' type="h3" dataAutomation='header-modal'>{textLabels.headingText[showModalFor]}</Heading>}
            {textLabels.messageText[showModalFor] && <Paragraph className={`${textLabels.paragraph[showModalFor] || 'mt-6 mb-6'} text-center`}>{textLabels.messageText[showModalFor]}</Paragraph>}
            {textLabels.okButtonText[showModalFor] &&
                <Button className='mx-auto' variant="Primary" size="medium" onClick={() => handleVerifyDocumentsStates()} dataAutomation={textLabels.automation[showModalFor]}>{textLabels.okButtonText[showModalFor]}</Button>}
        </Modal>
    )
}
export default ModalVerifyDocumentPopUps
