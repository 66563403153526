import { MO_ENGAGE_STATUS_ACTION } from "constant/lms";
import { trackEvent } from "services/moengage.service";
import lms_mo_engage from "../../assets/mo_engage/lms_moengage.json";

const events = lms_mo_engage.entity;

export const moEngageCategoryCardClick = (props = {}, actionKeyType) => {
    const attributes = setMoEngageAttributesCategoryCard({ actionKeyType, props });
    trackEvent(events.category_card_click.event_name, attributes);
};

export const moEngageDownloadCertificate = ({ category_title_value }) => {
    const { category_title_key } = events.download_certificate_click.attributes_download_certificate_click;

    const attributes = {
        [category_title_key]: category_title_value,
    }
    trackEvent(events.download_certificate_click.event_name, attributes);
};

export const moEngageDownloadCertificateLike = ({ category_title_value }) => {
    const { category_title_key } = events.download_certificate_like_click.attributes_download_certificate_click;

    const attributes = {
        [category_title_key]: category_title_value,
    }
    trackEvent(events.download_certificate_like_click.event_name, attributes);
};

export const moEngageContinueWatching = ({ video_title_value }) => {
    const { video_title_key } = events.continue_watching_click.attributes_continue_watching_click;

    const attributes = {
        [video_title_key]: video_title_value,
    }
    trackEvent(events.continue_watching_click.event_name, attributes);
};

export const moEngageCourseCard = ({ title_value, status_value, author_name_value, learning_path_value }) => {
    const { title_key, status_key, author_name_key, learning_path_key } = events.course_card_click.attributes_course_card_click;

    const attributes = {
        [title_key]: title_value,
        [status_key]: events.status_action[MO_ENGAGE_STATUS_ACTION[status_value]],
        [author_name_key]: author_name_value,
        [learning_path_key]: learning_path_value
    }
    trackEvent(events.course_card_click.event_name, attributes);
};

export const moEngageCourseCardCopy = ({ title_value, status_value, author_name_value, learning_path_value }) => {
    const { title_key, status_key, author_name_key, learning_path_key } = events.LMS_learning_path_Card_Copy.attributes_course_card_copy;

    const attributes = {
        [title_key]: title_value,
        [status_key]: events.status_action[MO_ENGAGE_STATUS_ACTION[status_value]],
        [author_name_key]: author_name_value,
        [learning_path_key]: learning_path_value
    }
    trackEvent(events.LMS_learning_path_Card_Copy.event_name, attributes);
};

export const moEngageTopicCard = ({ title_value, status_value, learning_path_value, course_value }) => {
    const { title_key, status_key, learning_path_key, course_key } = events.topic_card_click.attributes_topic_card_click;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value
    }
    trackEvent(events.topic_card_click.event_name, attributes);
};

export const moEngageTopicCardCopy = ({ title_value, status_value, learning_path_value, course_value }) => {
    const { title_key, status_key, learning_path_key, course_key } = events.topic_Card_Copy.attribute_LMS_Topic_Card_Copy;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value
    }
    trackEvent(events.topic_Card_Copy.event_name, attributes);
};


export const moEngageVideoClick = ({ video_title_value, title_value, status_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, status_key, learning_path_key, course_key } = events.video_click.attributes_video_click;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [status_key]: events.status_action[MO_ENGAGE_STATUS_ACTION[status_value]],
        [video_title_key]: video_title_value
    }
    trackEvent(events.video_click.event_name, attributes);
};

export const moEngagePlayVideo = ({ video_title_value, title_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, learning_path_key, course_key } = events.video_play_click.attributes_play_video;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [video_title_key]: video_title_value
    }
    trackEvent(events.video_play_click.event_name, attributes);
};

export const moEngagePauseVideo = ({ video_title_value, title_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, learning_path_key, course_key } = events.LMS_Video_Detail_Pause_Video.attributes;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [video_title_key]: video_title_value
    }
    trackEvent(events.LMS_Video_Detail_Pause_Video.event_name, attributes);
};

export const moEngageVideoBackClick = ({ video_title_value, title_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, learning_path_key, course_key } = events.LMS_Video_Detail_Back_Click.attributes;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [video_title_key]: video_title_value
    }
    trackEvent(events.LMS_Video_Detail_Back_Click.event_name, attributes);
};

export const moEngageVideoCopyClick = ({ video_title_value, title_value, status_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, status_key, learning_path_key, course_key } = events.LMS_Video_Detail_Copy.attributes;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [status_key]: events.status_action[MO_ENGAGE_STATUS_ACTION[status_value]],
        [video_title_key]: video_title_value
    }
    trackEvent(events.LMS_Video_Detail_Copy.event_name, attributes);
};

export const moEngageVideoPlay = ({ title_value }) => {
    const { title_key } = events.video_play_click.attributes_video_play_click;

    const attributes = {
        [title_key]: title_value,
    }
    trackEvent(events.video_play_click.event_name, attributes);
};

export const moEngageVideoPause = ({ title_value }) => {
    const { title_key } = events.video_pause_click.attributes_video_pause_click;

    const attributes = {
        [title_key]: title_value,
    }
    trackEvent(events.video_pause_click.event_name, attributes);
};

export const moEngageVideoReplay = ({ title_value }) => {
    const { title_key } = events.video_replay_click.attributes_video_replay_click;

    const attributes = {
        [title_key]: title_value,
    }
    trackEvent(events.video_replay_click.event_name, attributes);
};
// not done
export const moEngageVideoFullscreen = ({ video_title_value, title_value, learning_path_value, course_value }) => {
    const { video_title_key, title_key, learning_path_key, course_key } = events.video_fullscreen_click.attributes_video_fullscreen_click;

    const attributes = {
        [title_key]: title_value,
        [learning_path_key]: learning_path_value,
        [course_key]: course_value,
        [video_title_key]: video_title_value
    }
    trackEvent(events.video_fullscreen_click.event_name, attributes);
};


export const moEngageLmsQuickLink = (attributes = {}) => {
    trackEvent(events.lms_quick_link_click.event_name, attributes);
};

export const moEngageLmsFollowVaidyaPopup = ({ course_name, author_id, user_id}) => {
    const { course_key, authorId_key, userId_key } = events.LMS_Follow_Vaidya_Popup.attributes;
    const attributes = {
        [course_key]: course_name,
        [authorId_key]: author_id,
        [userId_key]: user_id
    }
    trackEvent(events.LMS_Follow_Vaidya_Popup.event_name, attributes);
};

export const moEngageLmsAuthoredByClick = ({ course_name, author_id, user_id }) => {
    const { course_key, authorId_key, userId_key} = events.LMS_Authored_By_Click.attributes;
    const attributes = {
        [course_key]: course_name,
        [authorId_key]: author_id,
        [userId_key]: user_id
    }
    trackEvent(events.LMS_Authored_By_Click.event_name, attributes);
};

function setMoEngageAttributesCategoryCard({ actionKeyType, props }) {
    const { title_key, total_courses_key, total_likes_key,
        total_view_key, action_key, status_key } = events.category_card_click.attributes_category_card_click;

    const attributes = {
        [title_key]: props?.title,
        [total_courses_key]: props?.total_count,
        [total_likes_key]: props?.totalLikes,
        [total_view_key]: props?.total_views || 0,
        [action_key]: events.lms_category_card_action[actionKeyType],
        [status_key]: events.status_action[MO_ENGAGE_STATUS_ACTION[props?.status]],
    }
    return attributes;
}