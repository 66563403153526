import { getMedicineList, getMedicineListById } from "../../services/medicine.service";
const appId = process.env.NEXT_PUBLIC_APP_ID;

export const searchMedicine = async (search, auth, limit = 10, offset = 0) => {
    const body = {
        'userId': auth?.user?.id,
        'user_type': auth?.user?.userType,
        'brand_filter': [],
        'category_filter': [],
        'is_bookmark': 0,
        'is_deal': 0,
        'id':'',
        'limit': limit,
        'skip': offset,
        'search': search,
        'is_reseller':0
    }
    return getMedicineListById(body, auth?.token)

}
