export const repeatSmartCenterRowsProps = {
    imageHeading: null,
    imagePosition: null,
}

export const SMARTCENTER_BANNER = {
    heading: `Experience the Journey of Healing`,
    description: `Let’s start the healing journey with authentic Ayurveda & Ayurveda expert that will rejuvenate the body, mind & soul`,
}

export const SMARTCENTER_OPTIONS = [
    {
        key: 'EXPERTS',
        title: 'Experts',
        description: 'You can Trust'
    },
    {
        key: 'GENUINE',
        title: 'Genuine',
        description: 'Treatment'
    },
    {
        key: 'TRAINED',
        title: 'Trained',
        description: 'Staff'
    },
    {
        key: 'AUTHENTIC',
        title: 'Authentic',
        description: 'Product'
    },
];

export const SC_TESTIMONIAL_HEADING = 'Healing Stories';

export const SC_TESTIMONIAL_LIST = [
    { id: 1, name: 'Divya Soni', userType: 'Delhi', avatar: '/images/smartcenter/divyaSoni.png', description: `Migraine is completely curable in Ayurveda. Get the right treatment for all types of pain problems with expert Ayurvedic Doctors. If you facing migraine problems then you can contact our expert Ayurvedic Doctor` },
    { id: 2, name: 'Rampukar', userType: 'Gurugram', avatar: '/images/smartcenter/ram_pukar.png', description: `मैं रामपुकार हूं. पिछले साल, एक मित्र श्री राजन के माध्यम से डॉ. स्तुति की सिफारिश पर, मैं पुरानी गैस्ट्रिक समस्याओं के लिए गया था। सर्वाइकल दर्द के लिए 14 दिनों के पंचकर्म उपचार के बाद, मुझे 70% से अधिक राहत का अनुभव हुआ। अनुवर्ती कार्रवाई में, क्लिनिक कर्मचारी एक शांत वातावरण बनाते हैं। स्वास्थ्य सुधार के लिए आभारी हूं|` },
    { id: 3, name: 'Sukriti', userType: 'Gurugram', avatar: '/images/smartcenter/sukriti.png', description: `मैं सुकृति हूं. इस केंद्र का दौरा करना और डॉ. इप्शा से मिलना अद्भुत रहा। उनके व्यापक ज्ञान और चौकस दृष्टिकोण ने मुझे प्रभावित किया। मित्रवत कर्मचारी और आरामदायक वातावरण निरोगस्ट्रीट आयुर्वेद केंद्र को आयुर्वेद के लाभों की खोज करने वाले किसी भी व्यक्ति के लिए अत्यधिक अनुशंसित बनाते हैं।` },
    { id: 4, name: 'Ashwani Dabra', userType: 'Gurugram', avatar: '/images/smartcenter/ashwaniDabra.png', description: `आयुर्वेदिक उपचार द्वारा कंधे की अकड़न (फ्रोजेन शोल्डर) की तकलीफ से छुटकारा पाया जा सकता है। कई लोग कंधे की अकड़न को नजर अंदाज करते है जो कि सही नहीं है। यह परेशानी धीरे-धीरे शुरू होती है और आगे चलकर गंभीर रूप धारण कर लेती है, जिसके कारण आपका हाथ काम करना बंद कर देता है। यह बीमारी ज्यादातर 35 से ऊपर आयुवर्ग के लोगो में देखा जाता है।` },
]


export const TreatmentDropdown = [
    {
        label: 'Pain Management', redirection: '/treatments/pain-management/', subMenuList: [
            { label: 'Rheumatoid Arthritis', redirection: '/treatments/pain-management/rheumatoid-arthritis' },
            { label: 'Osteoarthritis', redirection: '/treatments/pain-management/osteoarthritis' },
            { label: 'Cervical pain', redirection: '/treatments/pain-management/cervical-pain' },
            { label: 'Ankylosing spondylitis', redirection: '/treatments/pain-management/ankylosing-spondylitis' },
            { label: 'Multiple sclerosis', redirection: '/treatments/pain-management/multiple-sclerosis' },
            { label: 'Nerve damage (neuropathy', redirection: '/treatments/pain-management/nerve-damage-neuropathy' },
            { label: 'Gout', redirection: '/treatments/pain-management/gout' },
            { label: 'Muscle pain', redirection: '/treatments/pain-management/muscle-pain' },
            { label: 'Sciatica', redirection: '/treatments/pain-management/sciatica' },
            { label: 'Low back Pain', redirection: '/treatments/pain-management/low-back-pain' },
            { label: 'Headache', redirection: '/treatments/pain-management/headache' },
            { label: 'Knee Joint Pain', redirection: '/treatments/pain-management/knee-joint-pain' },
            { label: 'Frozen Shoulder', redirection: '/treatments/pain-management/frozen-shoulder' },
        ]
    },
    {
        label: 'Skin Diseases', redirection: '/treatments/skin-diseases', subMenuList: [
            { label: 'Acne (Acne vulgaris)', redirection: '/treatments/skin-diseases/acne-acne-vulgaris' },
            { label: 'Psoriasis', redirection: '/treatments/skin-diseases/psoriasis' },
            { label: 'Eczema (Atopic dermatitis)', redirection: '/treatments/skin-diseases/eczema-atopic-dermatitis' },
            { label: 'Urticaria (Hives)', redirection: '/treatments/skin-diseases/urticaria-hives' },
            { label: 'Skin Allergy', redirection: '/treatments/skin-diseases/skin-allergy' },
            { label: 'Dandruff', redirection: '/treatments/skin-diseases/dandruff' },
            { label: 'Vitiligo', redirection: '/treatments/skin-diseases/vitiligo' },
            { label: 'Ringworm', redirection: '/treatments/skin-diseases/ringworm' },
        ]
    },
    {
        label: 'Digestive Issues', redirection: '/treatments/digestive-issues', subMenuList: [
            { label: 'Acidity', redirection: '/treatments/digestive-issues/acidity' },
            { label: 'Bloating', redirection: '/treatments/digestive-issues/bloating' },
            { label: 'Constipation', redirection: '/treatments/digestive-issues/constipation' },
            { label: 'Ulcerative Colitis', redirection: '/treatments/digestive-issues/ulcerative-colitis' },
            { label: 'Diarrhoea', redirection: '/treatments/digestive-issues/diarrhoea' },
            { label: 'GERD', redirection: '/treatments/digestive-issues/gerd' },
            { label: 'IBS', redirection: '/treatments/digestive-issues/ibs' },
            { label: 'Anaemia', redirection: '/treatments/digestive-issues/anaemia' },
            { label: 'Jaundice', redirection: '/treatments/digestive-issues/jaundice' },
            { label: 'Gallstone', redirection: '/treatments/digestive-issues/gallstone' },
            { label: 'Liver Cirrhosis', redirection: '/treatments/digestive-issues/liver-cirrhosis' },
            { label: 'Crohn Disease', redirection: '/treatments/digestive-issues/crohn-disease' },
            { label: 'Celiac Disease', redirection: '/treatments/digestive-issues/celiac-disease' },
            { label: 'Piles', redirection: '/treatments/digestive-issues/piles' },
            { label: 'Fistula', redirection: '/treatments/digestive-issues/fistula' },
            { label: 'Fissure', redirection: '/treatments/digestive-issues/fissure' },
            { label: 'Haemorrhoids', redirection: '/treatments/digestive-issues/haemorrhoids' },

        ]
    },
    {
        label: 'Lifestyle Disorders', redirection: '/treatments/lifestyle-disorders', subMenuList: [
            { label: 'Hypertension', redirection: '/treatments/lifestyle-disorders/hypertension' },
            { label: 'Obesity', redirection: '/treatments/lifestyle-disorders/obesity' },
            { label: 'High Cholesterol', redirection: '/treatments/lifestyle-disorders/high-cholesterol' },
            { label: 'Thyroid', redirection: '/treatments/lifestyle-disorders/thyroid' },
            { label: 'Diabetes', redirection: '/treatments/lifestyle-disorders/diabetes' },
        ]
    },
    {
        label: 'Female Health Issues', redirection: '/treatments/female-health-issues', subMenuList: [
            { label: 'Leucorrhoea', redirection: '/treatments/female-health-issues/leucorrhoea' },
            { label: 'Menstruation Problems', redirection: '/treatments/female-health-issues/menstruation-problems' },
            { label: 'PCOD', redirection: '/treatments/female-health-issues/pcod' },
            { label: 'Fibroids', redirection: '/treatments/female-health-issues/fibroids' },

        ]
    },
    {
        label: 'Kidney Problems', redirection: '/treatments/kidney-problems', subMenuList: [
            { label: 'Kidney Failure', redirection: '/treatments/kidney-problems/kidney-failure' },
            { label: 'Kidney Stones', redirection: '/treatments/kidney-problems/kidney-stones' },
            { label: 'UTI', redirection: '/treatments/kidney-problems/uti' },


        ]
    },
    {

        label: 'Liver Problems', redirection: '/treatments/liver-problems', subMenuList: [
            { label: 'Fatty Liver', redirection: '/treatments/liver-problems/fatty-liver' },
            { label: 'Anaemia', redirection: '/treatments/liver-problems/anaemia' },
            { label: 'Jaundice', redirection: '/treatments/liver-problems/jaundice' },
            { label: 'Gallstone', redirection: '/treatments/liver-problems/gallstone' },
            { label: 'Liver Cirrhosis', redirection: '/treatments/liver-problems/liver-cirrhosis' },
        ]
    },
    {
        label: 'Respiratory Problems', redirection: '/treatments/respiratory-problems', subMenuList: [
            { label: 'Asthma', redirection: '/treatments/respiratory-problems/asthma' },
            { label: 'Cough', redirection: '/treatments/respiratory-problems/cough' },
            { label: 'Sinusitis', redirection: '/treatments/respiratory-problems/sinusitis' },
            { label: 'Rhinitis', redirection: '/treatments/respiratory-problems/rhinitis' },
        ]
    },
    // { label: 'Digestive Issues', redirection: '/digestive issues' },
    // { label: 'Lifestyle Disorders', redirection: '/lifestyle disorders' },
    // { label: 'Female Health Issues', redirection: '/female health issues' },
    // { label: 'Kidney Problems', redirection: '/kidney problems' },
    // { label: 'Liver Problems', redirection: '/liver problems' },
]

export const ServicesDropdown = [
    { label: 'Panchakarma', redirection: '/services/panchakarma' },
    { label: 'Pulse Diagnosis', redirection: '/services/pulse-diagnosis' },
    { label: 'Prakrati Analysis', redirection: '/services/prakrati-analysis' },
]

export const HealthConditions = [
    {
        label: 'Health Conditions', redirection: '/health-conditions', subMenuList: [
            { label: 'Diabetes Care', redirection: '/health-conditions/diabetes-care' },
            { label: 'Liver Care', redirection: '/health-conditions/liver-care' },
            { label: 'Leukorrhea Care', redirection: '/health-conditions/leukorrhea-care' },
            { label: 'Hair Care', redirection: '/health-conditions/hair-care' }
        ]
    },
    { label: 'All Medicine ', redirection: '/medicines/allMedicines' },
]

export const SC_FAQ_COMMON_HEADING = 'FAQs';

export const SC_FAQ_COMMON = [
    {
        id: 1,
        title: `How do I schedule a physical Ayurvedic consultation through your website?`,
        description: `Sorry, NirogStreet does not facilitate physical consultation as of now. However, you can book an online consultation with expert Ayurveda Doctors from the homepage through the website.`,
    },
    {
        id: 2,
        title: `Can I select a specific Ayurvedic practitioner for my physical consultation?`,
        description: `No, we assign doctors based on their expertise and availability. Once the doctor is assigned, you can view their profiles in the Find Doctors section on the website.`,
    },
    {
        id: 3,
        title: `How do I pay for my Ayurvedic consultation?`,
        description: `Consultation is absolutely free! However, the doctor can choose to charge you for follow-up appointments as per their discretion.`,
    },
    {
        id: 4,
        title: `How do I purchase medicines after the consultation?`,
        description: `Your doctor will place the order for you either during or after the consultation. Delivery typically takes 4-7 business days, and you can choose to pay upon delivery or online using UPI or debit/credit cards.`,
    },
    {
        id: 5,
        title: `Can I reschedule or cancel my appointment?`,
        description: `Yes, you can reschedule or cancel your appointment by giving prior notice to the Relationship Manager assigned.`,
    },
];

export const SC_TESTIMONIAL_HEADING_COMMON = 'Healing Stories';

export const SKIN_DISEASE_STORY = [
    { id: 1, name: 'Lakshay', avatar: '/images/smartcenter/lakshay.jpg', description: `Very satisfied with the prescription Dr Jyothi gave. Excellent consultation. I had been suffering from patches on my head for the last 1 year but the medicine worked like magic.` },
    { id: 2, name: 'Amit', avatar: '/images/smartcenter/amit.jpg', description: `I was suffering from immense itching on my back for the past few months. Online consultation really helped. Thank you, NirogStreet!` },
    { id: 3, name: 'Ayush', avatar: '/images/smartcenter/ayush.jpg', description: `My skin had become scaly in the winter season and I was ashamed of the way I had started looking. That is when I came across the Online Ayurveda Consultation Program and I am very happy after consulting with Dr. Snehal. She was superb!` },
    { id: 4, name: 'Kunal', avatar: '/images/smartcenter/kunal.jpg', description: `I am very happy that I chose NirogStreet after recommendaton from my friend. I am fully cured and very happy after consultation.` },
    { id: 5, name: 'Mahir Gautam', avatar: '/images/smartcenter/skinCare/mahir-gautam.jpg', description: `Dr. Jayesh Thakkar’s consultation helped me fight eczema and I would be happy to recommend him to my friends in the future.` },
]

export const FEMALE_ISSUE_STORY = [
    { id: 1, name: 'Aditi', avatar: '/images/smartcenter/femaleIssue/aditi.jpg', description: `I consulted Dr. Snehal after attending her seminar in Bareilly and I have to say, her PCOS prescription has really helped. My cycles are regular and I feel healthier` },
    { id: 2, name: 'Sukriti', avatar: '/images/smartcenter/femaleIssue/sukriti.jpg', description: `It was a pleasure visiting the NirogStreet Smart Centre for treating fibroids and I am very satisfied with the results. The consultation process was very smooth and Dr. Jyothi really helped me through.` },
    { id: 3, name: 'Neha', avatar: '/images/smartcenter/femaleIssue/neha.jpg', description: `I was very apprehensive of online consultation but I am very happy that there was 100% privacy during consultation. The process was smooth and I will recommend NirogStreet to my friends in Lucknow.` },
    { id: 4, name: 'Harinder Kaur', avatar: '/images/smartcenter/femaleIssue/harinder-Kaur.jpg', description: `Really nice consultation experience. Smooth and easy. The appointment was conducted on time and I was really impressed by the quality of consultation.` },
    { id: 5, name: 'Naina Kalra', avatar: '/images/smartcenter/femaleIssue/naina-kalra.jpg', description: `I was really impressed by Dr. Nitika Ganjoo’s consultation process. She patiently listened to me and gave a really good prescription. I will recommend her to my friends.` },
]

export const DIGESTIVE_ISSUE_STORY = [
    { id: 1, name: 'Aditi', avatar: '/images/smartcenter/femaleIssue/aditi.jpg', description: `Discovering Ayurveda has been life-changing for me. After struggling with Acidity for years, I decided to try Ayurvedic treatment. With personalized guidance from Ayurvedic doctor I have got relief from Acidity related issues.` },
    { id: 2, name: 'Amit', avatar: '/images/smartcenter/amit.jpg', description: `Excellent online consultation service. I booked an online consultation with the doctor and the experience was very good. The doctor answered all my questions and cleared my doubts. I will recommend NirogStreet to my colleagues and family.` },
    { id: 3, name: 'Neha', avatar: '/images/smartcenter/femaleIssue/neha.jpg', description: `I took online consultation from NirogStreet regarding Constipation issues and I can say it was a good decision. I followed the guidance of the doctor and now I feel much better as my constipation issues have been resolved.` },
    { id: 4, name: 'Ayush', avatar: '/images/smartcenter/ayush.jpg', description: `For years, I suffered from bloating until a friend recommended Ayurvedic treatment. I consulted online from NirogStreet, and the doctor understood my issue very well and provided me Ayurvedic treatment that helped a lot.` },
    { id: 5, name: 'Tarun Sharma', avatar: '/images/smartcenter/digestive/acidity/tarun-sharma.jpg', description: `I was really impressed by Dr. RK Tiwari's consultation process. His prescription really helped me fight gastric issues.` },
]

export const SC_YOUTUBE_COMMON_HEADING_COMMON = 'Videos';

export const SC_YOUTUBE_COMMON_LIST = [
    {
        id: 1,
        video: 'https://www.youtube.com/watch?v=BQXyM5cloOA&list=PLBxD-KbSeT1ulrEb8F3fXCd-Wkh5dG4SX',
    },
    {
        id: 2,
        video: 'https://www.youtube.com/watch?v=cKyzkC8TzXc&list=PLBxD-KbSeT1ulrEb8F3fXCd-Wkh5dG4SX',
    },
    {
        id: 3,
        video: 'https://www.youtube.com/watch?v=KpgEVjL2-1Y&list=PLBxD-KbSeT1ulrEb8F3fXCd-Wkh5dG4SX',
    },
];

export const SIMILAR_DISEASES_HEADING = 'Similar Diseases';

export const SKIN_DISEASES_MENU =
    [
        {
            id: 1,
            image: "/images/smartcenter/skinCare/acne.webp",
            title: 'Acne (Acne vulgaris)',
            url: '/treatments/skin-diseases/acne-acne-vulgaris'
        },
        {
            id: 2,
            image: "/images/smartcenter/skinCare/psoriasis.webp",
            title: 'Psoriasis',
            url: '/treatments/skin-diseases/psoriasis'
        },
        {
            id: 3,
            image: "/images/smartcenter/skinCare/eczema.webp",
            title: 'Eczema (Atopic dermatitis)',
            url: '/treatments/skin-diseases/eczema-atopic-dermatitis'
        },
        {
            id: 4,
            image: "/images/smartcenter/skinCare/urticaria.webp",
            title: 'Urticaria (Hives)',
            url: '/treatments/skin-diseases/urticaria-hives'
        },
        {
            id: 5,
            image: "/images/smartcenter/skinCare/skinAllergies.webp",
            title: 'Skin Allergies',
            url: '/treatments/skin-diseases/skin-allergy'
        },
        {
            id: 6,
            image: "/images/smartcenter/skinCare/dandruff.webp",
            title: 'Dandruff',
            url: '/treatments/skin-diseases/dandruff'
        },
        {
            id: 7,
            image: "/images/smartcenter/skinCare/vitiligo.webp",
            title: 'Vitiligo',
            url: '/treatments/skin-diseases/vitiligo'
        },
        {
            id: 8,
            image: "/images/smartcenter/skinCare/ringworm.webp",
            title: 'Ringworm',
            url: '/treatments/skin-diseases/ringworm'
        },
    ];

export const FEMALE_ISSUE_MENU =
    [
        {
            id: 1,
            image: "/images/smartcenter/femaleIssue/leucorrhoea.webp",
            title: 'Leucorrhoea',
            url: '/treatments/female-health-issues/leucorrhoea'
        },
        {
            id: 2,
            image: "/images/smartcenter/femaleIssue/menstruationProblems.webp",
            title: 'Menstruation Problems',
            url: '/treatments/female-health-issues/menstruation-problems'
        },
        {
            id: 3,
            image: "/images/smartcenter/femaleIssue/pcod.webp",
            title: 'PCOD',
            url: '/treatments/female-health-issues/pcod'
        },
        {
            id: 4,
            image: "/images/smartcenter/femaleIssue/fibroids.webp",
            title: 'Fibroids',
            url: '/treatments/female-health-issues/fibroids'
        },
    ];

    export const DIGESTIVE_ISSUE_MENU =
    [
        {
            id: 1,
            image: "/images/smartcenter/digestive/acidity/overview.webp",
            title: 'Acidity',
            url: '/treatments/digestive-issues/acidity'
        },
        {
            id: 2,
            image: "/images/smartcenter/digestive/bloating/overview.webp",
            title: 'Bloating',
            url: '/treatments/digestive-issues/bloating'
        },
        {
            id: 3,
            image: "/images/smartcenter/digestive/constipation/overview.webp",
            title: 'Constipation',
            url: '/treatments/digestive-issues/constipation'
        },
        {
            id: 4,
            image: "/images/smartcenter/digestive/ulcerative-colitis/overview.webp",
            title: 'UlcerativeColitis',
            url: '/treatments/digestive-issues/ulcerative-colitis'
        },
        {
            id: 5,
            image: "/images/smartcenter/digestive/diarrhoea/overview.webp",
            title: 'Diarrhoea',
            url: '/treatments/digestive-issues/diarrhoea'
        },
        {
            id: 6,
            image: "/images/smartcenter/digestive/gerd/overview.webp",
            title: 'GERD',
            url: '/treatments/digestive-issues/gerd'
        },
        {
            id: 7,
            image: "/images/smartcenter/digestive/ibs/symptoms.webp",
            title: 'IBS',
            url: '/treatments/digestive-issues/ibs'
        },
        {
            id: 8,
            image: "/images/smartcenter/digestive/jaundice/overview.webp",
            title: 'Jaundice',
            url: '/treatments/digestive-issues/jaundice'
        },
        {
            id: 9,
            image: "/images/smartcenter/digestive/gallstone/overview.webp",
            title: 'Gallstone',
            url: '/treatments/digestive-issues/gallstone'
        },
        {
            id: 10,
            image: "/images/smartcenter/digestive/liver-cirrhosis/overview.webp",
            title: 'Liver Cirrhosis',
            url: '/treatments/digestive-issues/liver-cirrhosis'
        },
        {
            id: 11,
            image: "/images/smartcenter/digestive/crohn-disease/overview.webp",
            title: "Crohn's Disease",
            url: '/treatments/digestive-issues/crohn-disease'
        },
        {
            id: 12,
            image: "/images/smartcenter/digestive/celiac-disease/overview.webp",
            title: 'Celiac Disease',
            url: '/treatments/digestive-issues/celiac-disease'
        },
        {
            id: 13,
            image: "/images/smartcenter/digestive/piles/overview.webp",
            title: 'Piles',
            url: '/treatments/digestive-issues/piles'
        },
        {
            id: 14,
            image: "/images/smartcenter/digestive/fistula/overview.webp",
            title: 'Fistula',
            url: '/treatments/digestive-issues/fistula'
        },
        {
            id: 15,
            image: "/images/smartcenter/digestive/fissure/overview.webp",
            title: "Fissure",
            url: '/treatments/digestive-issues/fissure'
        },
        {
            id: 16,
            image: "/images/smartcenter/digestive/haemorrhoids/overview.webp",
            title: 'Haemorrhoids',
            url: '/treatments/digestive-issues/haemorrhoids'
        },
    ];