import { useEffect } from "react";
const razerPayId = process.env.NEXT_PUBLIC_RAZERPAY_ID;
const s3BucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL

const Razorpay = ({
    openRayzorpay = false,
    currency = "INR",
    setOpenRayzorpay = () => { },
    user,
    totalAmount = 0,
    rayzorpayOrderId,
    onPaymentDone = () => { },
    onPaymentCancel = () => { },
    timeout = 600
}) => {

    const scriptUrl = 'https://checkout.razorpay.com/v1/checkout.js';

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        });
    };

    const build = () => {
        const options = {
            key: razerPayId,
            currency: currency,
            amount: Math.round(totalAmount * 100).toString(),
            order_id: rayzorpayOrderId,
            name: 'NirogStreet',
            description: 'NirogStreent Payment',
            image: `${s3BucketUrl}images/nirogstreet.png`,
            handler: function (response) {
                onPaymentDone(response);
            },
            prefill: {
                name: user?.user?.fullName,
                email: user?.user?.email,
                contact: user?.user?.mobileNumber.toString()
            },
            modal: {
                escape: false,
                ondismiss: onPaymentCancel
            },
            timeout: timeout
        };
        const gateway = new window.Razorpay(options);
        gateway.open();
    };

    const onLoadScriptFail = () => {
        alert('Razorpay SDK failed to load. Are you online?');
        setOpenRayzorpay(false);
        onPaymentCancel(false);
    };

    useEffect(async () => {
        if (openRayzorpay) {
            const response = await loadScript(scriptUrl);
            if (!response) {
                onLoadScriptFail();
            } else {
                build();
            }
        }
    }, [openRayzorpay]);

    return (
        <>
        </>
    );
};

export default Razorpay;