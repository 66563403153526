import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import 'simplebar/dist/simplebar.min.css';

const CustomScroll = ({ children, autoHide, className, maxWidth, maxHeight, ...rest }) => {
    return <SimpleBar className={`${className} sc-bar w-full`} autoHide={autoHide}
        style={{ maxHeight, maxWidth }}
        {...rest}>
        {children}
    </SimpleBar>
}

CustomScroll.defaultProps = {
    className: '',
    autoHide: false,
}

CustomScroll.propTypes = {
    className: PropTypes.string,
}

export default CustomScroll; 