import { trackEvent } from "services/moengage.service";
import profile_moengage from "../../assets/mo_engage/profile.json";

const events = profile_moengage.entity;

export const onProfileChangeProfilePicClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.change_picture_click.event_name, attributes);
    callback();
};

export const onProfileIconClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_icon_click.event_name, attributes);
    callback();
};

export const onProfileMenuClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_menu.event_name, attributes);
    callback();
};

export const onProfilePostClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_post_click.event_name, attributes);
    callback();
};

export const onProfileFollowersClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_followers_click.event_name, attributes);
    callback();
};

export const onProfileFollowingClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_followings_click.event_name, attributes);
    callback();
};

export const onProfileOtherPostClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_other_post_click.event_name, attributes);
    callback();
};

export const onProfileOtherFollowersClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_other_followers_click.event_name, attributes);
    callback();
};

export const onProfileOtherFollowingClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_other_following_click.event_name, attributes);
    callback();
};

export const onProfilePersonalDetailClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.personal_details_click.event_name, attributes);
    callback();
};

export const onProfileEducationClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.education_click.event_name, attributes);
    callback();
};

export const onProfileServicesClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.services_click.event_name, attributes);
    callback();
};

export const onProfileSpecializationClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.specialisation_click.event_name, attributes);
    callback();
};

export const onProfileExperienceClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.experience_click.event_name, attributes);
    callback();
};

export const onProfileAwardsClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.award_click.event_name, attributes);
    callback();
};

export const onProfileMembershipsClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.membership_click.event_name, attributes);
    callback();
};

export const onProfileClinicClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.clinic_click.event_name, attributes);
    callback();
};

export const onProfileVaidyaToolClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_vaidya_tool_click.event_name, attributes);
    callback();
};

export const OnClickProfileMobileNumberEdit = (callback = () => { }, attributes = {}) => {
    trackEvent(events.edit_mobile_number.event_name, attributes);
    callback();
};

export const OnClickProfileMobileNumberEditNext = (callback = () => { }, attributes = {}) => {
    trackEvent(events.edit_mobile_number_next.event_name, attributes);
    callback();
};

export const OnClickProfileMobileNumberEditProceed = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_mobile_number_edit_proceed.event_name, attributes);
    callback();
};

export const OnClickProfileMobileNumberEditResendOTP = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_mobile_number_edit_resend_otp.event_name, attributes);
    callback();
};

export const OnClickProfileMobileNumberEditChangeNumber = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_mobile_number_edit_change_number.event_name, attributes);
    callback();
};

export const onProfilePersonalDetailsSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_personal_details_click.event_name, attributes);
    callback();
};

export const onProfileEducationAddMoreClick = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_education_click.event_name, attributes);
    callback();
};

export const onSelectProfileEducationQualification = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_education_qualification.event_name, attributes);
    callback();
};

export const onSelectProfileEducationUniversity = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_education_university.event_name, attributes);
    callback();
};

export const onAddProfileEducationYaer = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_education_year.event_name, attributes);
    callback();
};

export const onProfileEducationUploadDocument = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_eduction_upload_document.event_name, attributes);
    callback();
};

export const onProfileEducationSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_education_click.event_name, attributes);
    callback();
};

export const onProfileServicesSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_services_click.event_name, attributes);
    callback();
};

export const onProfileSpecializationSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_specialisation_click.event_name, attributes);
    callback();
};

export const onProfileExperiencesSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_experience_click.event_name, attributes);
    callback();
};

export const onProfileAwardsSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_award_click.event_name, attributes);
    callback();
};

export const onProfileMembershipsSave = (callback = () => { }, attributes = {}) => {
    trackEvent(events.save_membership_click.event_name, attributes);
    callback();
};

export const onClickProfileServicesAddMore = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_service_click.event_name, attributes);
    callback();
};

export const onClickProfileSpecializationAddMore = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_specilization_click.event_name, attributes);
    callback();
};

export const onClickProfileExperiencesAddMore = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_experience_click.event_name, attributes);
    callback();
};

export const onClickProfileAwardsAddMore = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_award_click.event_name, attributes);
    callback();
};

export const onClickProfileMembershipsAddMore = (callback = () => { }, attributes = {}) => {
    trackEvent(events.add_more_membership_click.event_name, attributes);
    callback();
};

export const onProfileServicesSearch = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_services_search.event_name, attributes);
    callback();
};

export const onProfileSpecializationSearch = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_specialization_search.event_name, attributes);
    callback();
};

export const onClickProfileServicesCrossIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_services_cross_icon.event_name, attributes);
    callback();
};

export const onClickProfileSpecializationCrossIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.profile_specialization_cross_icon.event_name, attributes);
    callback();
};

export const onClickProfileExperiencesCrossIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.cancel_experience_click.event_name, attributes);
    callback();
};

export const onClickProfileAwardsCrossIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.cancel_award_click.event_name, attributes);
    callback();
};

export const onClickProfileMembershipsCrossIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.cancel_membership_click.event_name, attributes);
    callback();
};