import React from 'react'
import Modal from 'atoms/Modal'
import BodyForModal from 'molecules/Modals/BodyForModal'
import IconClose from 'atoms/SvgIcons/IconClose.svg'
import Text from 'atoms/Text'
import ImageNext from 'atoms/ImageNext'
import Image from 'next/image'

const STORE = {
    APP_STORE: {
        QR: '/images/nirogstreet-app-store-qr-code.svg',
        alternativeText: 'NirogStreet App Store',
    },
    PLAY_STORE: {
        QR: '/images/nirogstreet-play-store-qr-code.svg',
        alternativeText: 'NirogStreet Play Store',
    },
}

const ModalAppleAndPlayStore = ({ className, isModalOpen, handleModalStateChange, modalHeading, onRequestAppointment, consultDoctor, dataAutomation, type, usertype }) => {

    return (
        <Modal
            type="ghost"
            containerClass={`${isModalOpen || 'hidden'}`}
            className={`${className} max-w-full md:max-w-[790px] w-full mb-0 md:mb-7.5 customScrollbar`}
            handlerModalClose={handleModalStateChange}
            isModalOpen={isModalOpen}
            containerPadding='px-0'
            containerAlignment='items-end md:items-center'
            dataAutomation='medicine'
        >

            <div className='flex flex-col min-h-[400px] h-[calc(100vh_-_80px)] max-h-[450px] sm:max-h-[500px] md:max-h-[620px] relative customScrollbar'>

                <button type='reset' onClick={handleModalStateChange} className='group p-1 absolute right-4 top-4 z-10' data-automation={dataAutomation + '-modal-close'}>
                    <IconClose className="w-4 md:w-5 fill-none stroke-gray-900 group-hover:stroke-primary1-500" />
                </button>

                <BodyForModal type="bodyType1" className="flex gap-1 px-6 md:px-10 py-7.5 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                    <div>
                        <Text className='text-18-28 md:text-24-24 text-gray-900' fontWeight={600} >
                            NirogStreet
                        </Text>
                        <Text className='text-16-18 md:text-18-28 leading-5 md:leading-7 text-gray-600 mt-2 mb-7.5' fontWeight={400}>
                            Join us & Experience the World of Ayurveda
                        </Text>

                        <Text className='text-16-18 md:text-18-28 leading-5 md:leading-7 text-gray-700 mt-2 mb-3 ' fontWeight={600}>
                            Scan QR code to download app
                        </Text>

                        <ImageNext src={STORE[type]?.QR} alt={STORE[type]?.alternativeText} width={200} height={200} />
                    </div>

                    <div className='block w-[80%] md:w-full md:max-w-[437px]'>
                        <div className="relative image-container w-full h-full max-w-[437px]">                            
                            <Image                                
                                src={(usertype == 1 || !usertype) ? '/images/appscreen-individual.png' : '/images/nirogstreet-join-us-screen.png'}
                                alt='' layout="fill" className={'image'}
                            />
                        </div>
                    </div>
                </BodyForModal>
            </div>
        </Modal>
    )
}

export default ModalAppleAndPlayStore