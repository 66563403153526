import LinkAnchor from 'atoms/LinkAnchor';
import React from 'react'
import { moEngageBlogMediaType, moEngageBlogQuickLink } from '../blog/BlogMonengage';

import * as moengage from "services/moengage.service";
import { USER_TYPES } from 'constant';
import { onClickNirogPartner } from '../Reseller/ResellerMoengage';
import { useRouter } from 'next/router';
import { moEngageFeedQuickLink } from '../feeds/FeedMoEngage';
import Container from 'atoms/Container';
import AppBar from '../HomePage/AppBar';
import NestedDropdown from 'molecules/NestedDropdown';
import { ServicesDropdown, TreatmentDropdown, HealthConditions } from 'constant/smartcenter';
import { handleAnalyticEventHandleNavBarNavigation } from 'utils/ga-analytic';
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin';

const styles = {
    a: 'text-14-20 !text-gray-600 font-semibold hover:text-primary1-500',
    b: 'text-14-20 !text-gray-600  hover:text-primary1-500'
}
const QuickLinksWeb = ({ auth, isVerifiedUser, activeLink, entity, usertype, updateIsVerified, userAccess, isEnableLMS, attributes, handlerOpenStoreModal, popularLinkList, handleVaidyaClick, openHandleBookAppointment, redirectPopup, IndividualDropdown }) => {
    const router = useRouter();
    const route = router.pathname.split('/')[1];

    const niorogPartnerHandler = (e) => {
        e.preventDefault();
        onClickNirogPartner(() => {
            if (!isVerifiedUser) {
                window.open('/reseller', '_blank');
            } else if (updateIsVerified === 1 && (usertype === USER_TYPES?.DOCTORS || usertype === USER_TYPES?.DOCTOR_DISTRIBUTOR || usertype === USER_TYPES?.STUDENTS)) {
                window.open('/reseller', '_blank');
            } else {
                redirectPopup();
            }
        });
    }

    const NirogPartnerDropdown = [
        { label: 'Nirog Partner', redirection: '/treatments/pain-management/', onClick: niorogPartnerHandler },
        { label: 'Vaidya Tool', redirection: '/treatments/pain-management/', onClick: (e) => { e.preventDefault(); handleVaidyaClick('vaidya_tool') } },
    ]
    const LearningDropdown = [
        { label: 'Learning Courses', dataAutomation: 'header-learning', redirection: '/learning' },
        { label: 'Webinar', dataAutomation: 'header-webinar', redirection: '/webinar' },
        { label: 'Quiz', dataAutomation: 'header-quiz', redirection: '/quizzes' },
    ]

    return (
        <Container type='static' className={`flex items-center w-full `}>
            <div className='w-full flex gap-4 justify-between items-center'>
                <div className={`${auth?.user?.userType === USER_TYPES?.PATIENT ? ' xl:gap-9' : 'xl:gap-8'} flex items-center gap-5 `}>

                    {(!auth  && route != 'practitioner') && <>
                        <LinkAnchor href={`/ayurveda/doctors`} className={`${styles.a} ${activeLink('/ayurveda/doctors')}`} dataAutomation='find-doctors'>
                            <div onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)}>
                                Find Doctors
                            </div>
                        </LinkAnchor>

                        <LinkAnchor href={`/clinics`} className={`${styles.a} ${activeLink('clinics')}`} dataAutomation='clinics'>
                            <div onClick={() => moengage.trackEvent(entity.clinic_click.event_name, attributes)}>
                                Find Clinics
                            </div>
                        </LinkAnchor>
                        <NestedDropdown  href='/health-conditions' className={`${styles.b} ${activeLink('health-conditions')}`} list={HealthConditions} dataAutomation='treatment-cta'>Buy Medicine</NestedDropdown>
                        <NestedDropdown list={TreatmentDropdown} className={`${styles.b} ${activeLink('ayurveda')}`} dataAutomation='treatment-cta'>Treatments</NestedDropdown>
                        <LinkAnchor href={`/blogs`} className={`${styles.a} ${activeLink('blogs')}`} dataAutomation='blog'>
                            <div onClick={() => { moEngageBlogQuickLink(); moEngageBlogMediaType("/blogs") }}>
                                Health Blogs
                            </div>
                        </LinkAnchor>
                    </>}

                    {(!auth && route == 'practitioner') && <>
                        <NestedDropdown href='/medicines' list={popularLinkList} dataAutomation='buy-medicines' onClick={(event) => {handleAnalyticEventHandleNavBarNavigation({router});
                                            redirectionAfterLogin({ event, auth, router, redirectUrlLoggedIn: '/medicines', redirectUrlLoggedOut: '/login-practitioner' })
                 }}>
                            Buy Medicines
                        </NestedDropdown>

                        {(usertype === null || usertype !== USER_TYPES?.CHEMIST || usertype !== USER_TYPES?.CHEMIST_DISTRIBUTOR || usertype !== USER_TYPES?.RETAILER) && (
                            <NestedDropdown list={NirogPartnerDropdown} dataAutomation='nirog-partner' onClick={(e) => {
                                e.preventDefault();
                                onClickNirogPartner(() => {
                                    if (!isVerifiedUser) {
                                        // router.replace('/sign-ip')
                                        router.push('/reseller');
                                    } else if (updateIsVerified === 1 && (usertype === USER_TYPES?.DOCTORS || usertype === USER_TYPES?.DOCTOR_DISTRIBUTOR || usertype === USER_TYPES?.STUDENTS)) {
                                        router.push('/reseller');
                                    } else {
                                        redirectPopup();
                                    }
                                });
                            }}>
                                Partnership
                            </NestedDropdown>
                        )}

                        <LinkAnchor href={`/feeds`} className={`${styles.a} ${activeLink('feeds')}`} dataAutomation='feed'>
                            <div onClick={() => moEngageFeedQuickLink()}>
                                Feed
                            </div>
                        </LinkAnchor>

                        <NestedDropdown list={LearningDropdown} dataAutomation='learning-dropdown-cta'>Learning</NestedDropdown>

                        <LinkAnchor href={`/blogs`} className={`${styles.a} ${activeLink('blogs')}`} dataAutomation='blog'>
                            <div onClick={() => { moEngageBlogQuickLink(); moEngageBlogMediaType("/blogs") }}>
                                Health Blogs
                            </div>
                        </LinkAnchor>

                        {/* <LinkAnchor href={`/ayurvedic-doctor`} className={`${styles.a} ${activeLink('ayurvedic-doctor')}`} dataAutomation='find-doctors'>
                            <div onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)}>
                                Find Doctors
                            </div>
                        </LinkAnchor>

                        <LinkAnchor href={`/clinics`} className={`${styles.a} ${activeLink('clinics')}`} dataAutomation='clinics'>
                            <div onClick={() => moengage.trackEvent(entity.clinic_click.event_name, attributes)}>
                                Find Clinics
                            </div>
                        </LinkAnchor> */}

                        <LinkAnchor href={`/offers`} className={`bg-secondary-500 rounded-lg w-[88px] relative`} dataAutomation='find-doctors'>
                            <img src='/images/icon-offers-v2.png' alt='NirogStreet offers' className='absolute -left-[8px] -top-[8px]' />
                            <div className='py-2 text-14-18 font-semibold text-basic-white text-center px-1'>
                                Offers
                            </div>
                        </LinkAnchor>
                    </>}

                    {auth ? (auth?.user?.userType === USER_TYPES?.PATIENT || ((auth?.user?.userType === USER_TYPES?.PATIENT || !auth) && (route != 'practitioner' && route != 'login-practitioner' && route != 'sign-up-practitioner'))) ?
                        <>
                          <LinkAnchor href={`/ayurveda/doctors`} className={`${styles.a} ${activeLink('ayurveda/doctors')}`} dataAutomation='find-doctors'>
                                <div onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)}>
                                    Find Doctors
                                </div>
                            </LinkAnchor>
                            <LinkAnchor href={`/clinics`} className={`${styles.a} ${activeLink('clinics')}`} dataAutomation='clinics'>
                                <div onClick={() => moengage.trackEvent(entity.clinic_click.event_name, attributes)}>
                                    Find Clinics
                                </div>
                            </LinkAnchor>
                            <NestedDropdown  href='/health-conditions' list={HealthConditions} dataAutomation='treatment-cta'>Buy Medicine</NestedDropdown>
                            <NestedDropdown list={TreatmentDropdown} dataAutomation='treatment-cta'>Treatments</NestedDropdown>
                            {/* <NestedDropdown list={ServicesDropdown} dataAutomation='services-cta'>Services</NestedDropdown> */}
                            <LinkAnchor href={`/blogs`} className={`${styles.a} ${activeLink('blogs')}`} dataAutomation='blog'>
                                <div onClick={() => { moEngageBlogQuickLink(); moEngageBlogMediaType("/blogs") }}>
                                    Health Blogs
                                </div>
                            </LinkAnchor>
                        </> :
                        <>
                            {(auth?.user?.userType === USER_TYPES?.CHEMIST || auth?.user?.userType === USER_TYPES?.CHEMIST_DISTRIBUTOR || auth?.user?.userType === USER_TYPES?.RETAILER) ? (<>
                                <NestedDropdown href='/medicines' list={popularLinkList} dataAutomation='buy-medicines' onClick={() => handleAnalyticEventHandleNavBarNavigation({router})}>
                                    Buy Medicines
                                </NestedDropdown>
                                <LinkAnchor href={`/offers`} className={`bg-secondary-500 rounded-lg w-[88px] relative`} dataAutomation='find-doctors'>
                                    <img src='/images/icon-offers-v2.png' alt='NirogStreet offers' className='absolute -left-[8px] -top-[8px]' />
                                    <div className='py-2 text-14-18 font-semibold text-basic-white text-center px-1'>
                                        Offers
                                    </div>
                                </LinkAnchor>
                            </>) : (<>
                                <NestedDropdown href='/medicines' list={popularLinkList} dataAutomation='buy-medicines' onClick={() => handleAnalyticEventHandleNavBarNavigation({router})}>
                                    Buy Medicines
                                </NestedDropdown>

                                {(usertype === null || usertype !== USER_TYPES?.CHEMIST || usertype !== USER_TYPES?.CHEMIST_DISTRIBUTOR || usertype !== USER_TYPES?.RETAILER) && (
                                    <NestedDropdown list={NirogPartnerDropdown} dataAutomation='nirog-partner' onClick={(e) => {
                                        e.preventDefault();
                                        onClickNirogPartner(() => {
                                            if (!isVerifiedUser) {
                                                // router.replace('/sign-ip')
                                                router.push('/reseller');
                                            } else if (updateIsVerified === 1 && (usertype === USER_TYPES?.DOCTORS || usertype === USER_TYPES?.DOCTOR_DISTRIBUTOR || usertype === USER_TYPES?.STUDENTS)) {
                                                router.push('/reseller');
                                            } else {
                                                redirectPopup();
                                            }
                                        });
                                    }}>
                                        Partnership
                                    </NestedDropdown>
                                )}

                                <LinkAnchor href={`/feeds`} className={`${styles.a} ${activeLink('feeds')}`} dataAutomation='feed'>
                                    <div onClick={() => moEngageFeedQuickLink()}>
                                        Feed
                                    </div>
                                </LinkAnchor>

                                <NestedDropdown list={LearningDropdown} dataAutomation='learning-dropdown-cta'>Learning</NestedDropdown>

                                <LinkAnchor href={`/blogs`} className={`${styles.a} ${activeLink('blogs')}`} dataAutomation='blog'>
                                    <div onClick={() => { moEngageBlogQuickLink(); moEngageBlogMediaType("/blogs") }}>
                                        Health Blogs
                                    </div>
                                </LinkAnchor>
                                <LinkAnchor href={`/ayurveda/doctors`} className={`${styles.a} ${activeLink('/ayurveda/doctors')}`} dataAutomation='find-doctors'>
                                    <div onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)}>
                                        Find Doctors
                                    </div>
                                </LinkAnchor>


                                <LinkAnchor href={`/clinics`} className={`${styles.a} ${activeLink('clinics')}`} dataAutomation='clinics'>
                                    <div onClick={() => moengage.trackEvent(entity.clinic_click.event_name, attributes)}>
                                        Find Clinics
                                    </div>
                                </LinkAnchor>

                                <LinkAnchor href={`/offers`} className={`bg-secondary-500 rounded-lg w-[88px] relative`} dataAutomation='find-doctors'>
                                    <img src='/images/icon-offers-v2.png' alt='NirogStreet offers' className='absolute -left-[8px] -top-[8px]' />
                                    <div className='py-2 text-14-18 font-semibold text-basic-white text-center px-1'>
                                        Offers
                                    </div>
                                </LinkAnchor>
                            </>)}


                        </> : null}
                </div>

                <AppBar handlerOpenStoreModal={handlerOpenStoreModal} />
            </div>
        </Container >
    )
}

export default QuickLinksWeb