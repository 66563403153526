import { Svg } from "atoms/SvgIcon";

const IconMyMedicine = ({ ...rest }) => {
    return <Svg type='IconMyMedicine' {...rest}
        width="21" height="21" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
       <path d="M0.00403925 10.7525C0.00403925 7.90479 0.00403925 5.05713 0.00403925 2.20946C0.00403925 1.39758 0.432198 0.787651 1.1431 0.589729C1.32083 0.541258 1.51471 0.521062 1.70052 0.521062C2.50433 0.512983 4.35834 0.517022 5.15811 0.521062C5.30756 0.521062 5.41258 0.492787 5.51356 0.367571C5.69936 0.125216 5.96191 0 6.26486 0C8.38546 0 10.5101 0 12.6307 0C12.9458 0 13.1881 0.145413 13.4062 0.367571C13.4789 0.444316 13.6082 0.504905 13.7092 0.504905C14.513 0.517022 16.367 0.508944 17.1668 0.512983C18.2412 0.512983 18.8915 1.16734 18.8915 2.24986C18.8915 7.92095 18.8915 13.592 18.8915 19.2631C18.8915 20.3578 18.2533 21 17.1708 21C12.7236 21 6.17196 21 1.72475 21C0.642239 21 0.00403924 20.3578 0 19.2672C0 16.4276 0 13.588 0 10.7484L0.00403925 10.7525ZM17.8373 10.7686C17.8373 7.92095 17.8373 5.07328 17.8373 2.22562C17.8373 1.75707 17.6636 1.57934 17.2031 1.57934C16.3831 1.57934 14.513 1.5753 13.693 1.58338C13.5961 1.58338 13.4749 1.63993 13.4103 1.71264C13.1921 1.94691 12.9377 2.09233 12.6145 2.09233C10.502 2.09233 8.38546 2.09233 6.27294 2.09233C5.95788 2.09233 5.69532 1.96711 5.50548 1.72072C5.41258 1.59954 5.30756 1.5753 5.17022 1.5753C4.37449 1.57934 2.52856 1.5753 1.73687 1.5753C1.22389 1.5753 1.06232 1.73687 1.06232 2.25389C1.06232 7.92498 1.06232 13.5961 1.06232 19.2672C1.06232 19.7923 1.22793 19.9579 1.75303 19.9579C6.17599 19.9579 12.7034 19.9579 17.1264 19.9579C17.6797 19.9579 17.8373 19.8003 17.8373 19.2348C17.8373 16.4114 17.8373 13.588 17.8373 10.7646V10.7686Z" fill="#14142B"/>
<path d="M11.9905 17.4962C11.2954 17.4962 9.45636 17.4962 8.76122 17.4962C8.65563 17.4962 8.54564 17.4962 8.44444 17.4733C8.17167 17.4198 7.99568 17.2214 8.00008 16.9886C8.00448 16.7634 8.17607 16.5763 8.44004 16.5229C8.51924 16.5076 8.59843 16.5 8.67762 16.5C10.1339 16.5 13.8736 16.5 15.3299 16.5C15.8138 16.5 16.0998 16.8053 15.9678 17.1565C15.8842 17.3779 15.673 17.4962 15.3343 17.5C14.6039 17.5 12.7253 17.5 11.9949 17.5L11.9905 17.4962Z" fill="#14142B"/>
<path d="M11.9905 13.4962C11.2954 13.4962 9.45636 13.4962 8.76122 13.4962C8.65563 13.4962 8.54564 13.4962 8.44444 13.4733C8.17167 13.4198 7.99568 13.2214 8.00008 12.9886C8.00448 12.7634 8.17607 12.5763 8.44004 12.5229C8.51924 12.5076 8.59843 12.5 8.67762 12.5C10.1339 12.5 13.8736 12.5 15.3299 12.5C15.8138 12.5 16.0998 12.8053 15.9678 13.1565C15.8842 13.3779 15.673 13.4962 15.3343 13.5C14.6039 13.5 12.7253 13.5 11.9949 13.5L11.9905 13.4962Z" fill="#14142B"/>
<path d="M4.22343 13.169C4.56946 12.811 4.88252 12.4811 5.20218 12.1564C5.36695 11.9876 5.6207 11.9518 5.81843 12.0669C6.00297 12.1743 6.06229 12.3584 5.92388 12.5092C5.49218 12.9721 5.05059 13.4298 4.60241 13.8799C4.45082 14.0333 4.1773 14.0384 3.99605 13.9029C3.70275 13.683 3.41275 13.4579 3.12935 13.2304C2.95469 13.0897 2.96128 12.8979 3.12276 12.7675C3.29082 12.632 3.54457 12.6269 3.73241 12.7675C3.89718 12.8877 4.04548 13.0232 4.22343 13.1715V13.169Z" fill="#14142B"/>
<path d="M4.23352 17.1644C4.56472 16.8235 4.87936 16.5006 5.19069 16.175C5.3033 16.0597 5.43909 15.9828 5.63119 16.0033C5.97232 16.0392 6.11143 16.3237 5.89946 16.5518C5.65768 16.8133 5.40266 17.067 5.15426 17.3259C4.98866 17.4951 4.82637 17.6694 4.65746 17.836C4.45211 18.041 4.18715 18.0538 3.952 17.8744C3.68373 17.6719 3.41877 17.4643 3.15712 17.2567C2.95509 17.0952 2.94846 16.8978 3.13394 16.7594C3.30616 16.6287 3.5645 16.6364 3.75991 16.7851C3.91888 16.903 4.06792 17.0286 4.23352 17.1619V17.1644Z" fill="#14142B"/>
<path d="M9.94992 6.06173C10.2761 6.06173 11.7815 6.0548 12.0834 6.06173C12.2882 6.06866 12.4513 6.21418 12.4894 6.40475C12.5311 6.60571 12.4478 6.80667 12.2708 6.88982C12.1841 6.9314 12.0765 6.95219 11.9793 6.95219C11.7121 6.95912 10.2449 6.95219 9.94992 6.95219C9.94992 7.1185 9.94992 7.27095 9.94992 7.4234C9.94992 7.58625 9.95339 8.94563 9.93951 9.10501C9.91522 9.33715 9.73129 9.49654 9.50573 9.5C9.27322 9.5 9.07889 9.34062 9.05806 9.09808C9.04071 8.87287 9.04765 7.44419 9.04765 7.21898C9.04765 7.13582 9.04765 7.05613 9.04765 6.94872C8.74574 6.94872 7.26118 6.95219 6.97662 6.94872C6.70594 6.94179 6.51854 6.77548 6.50119 6.53295C6.48384 6.26962 6.65735 6.0652 6.92109 6.0548C7.16054 6.04441 8.59999 6.05134 8.83597 6.05134C8.89843 6.05134 8.96437 6.05134 9.04765 6.05134C9.04765 5.79494 9.04765 4.36626 9.04765 4.13412C9.04765 4.04403 9.04765 3.95395 9.06153 3.86733C9.09624 3.64558 9.28016 3.49659 9.50226 3.50006C9.72435 3.50006 9.91522 3.64905 9.93604 3.87426C9.95686 4.1168 9.94992 5.5628 9.95339 5.8088C9.95339 5.88503 9.95339 5.96125 9.95339 6.05827L9.94992 6.06173Z" fill="#14142B"/>
 </Svg>
};

export default IconMyMedicine;


