export const LmsPageType = {
    COURSES: 'courses',
    TOPICS: 'topics',
    TOPICS_VIDEO: 'TOPICS_VIDEO',
}

export const LmsPageHeading = {
    'courses': 'About this Learning Module',
    'topics': 'About this Course',
    'TOPICS_VIDEO': 'About this Topic',
}

export const COURSE_STATUS = {
    1: 'NOT_STARTED',
    2: 'IN_PROGRESS',
    3: 'COMPLETED',
}

export const MO_ENGAGE_STATUS_ACTION = {
    1: 'in_progress_key',
    2: 'not_started_key',
    3: 'completed_key',
}

export const LMS_MODULE_HEADING = 'About Learning Module:';