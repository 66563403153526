import { Svg } from "atoms/SvgIcon";

const IconReferralSidebar = ({ ...rest }) => {
    return <Svg viewBox='0 0 24 24' type='IconReferralSidebar' {...rest} >
<g clip-path="url(#clip0_98731_35880)">
<path d="M0 19.1807C0.0269231 19.0269 0.0615385 18.8769 0.0769231 18.7231C0.253846 17.1615 0.811539 15.7423 1.67692 14.4346C2.13077 13.7423 2.66923 13.1231 3.28077 12.5692C4.03846 11.8884 4.88077 11.3307 5.81154 10.9115C6.09231 10.7846 6.37692 10.6769 6.67692 10.55C6.51538 10.4154 6.36538 10.2884 6.21538 10.1654C5.22308 9.33459 4.62692 8.2692 4.33077 7.02305C4.19231 6.44613 4.17308 5.85767 4.22308 5.28074C4.31538 4.20382 4.70385 3.2192 5.35385 2.34997C5.93462 1.5692 6.66923 0.969204 7.55769 0.569204C8.04231 0.353819 8.53846 0.180742 9.06538 0.115358C9.33846 0.0807422 9.61154 0.0307422 9.88462 0.0422807C10.6 0.0730499 11.3077 0.149973 11.9846 0.411511C12.8231 0.730742 13.55 1.21536 14.1654 1.86151C14.6962 2.4192 15.0923 3.06536 15.3692 3.78459C15.6846 4.60382 15.7808 5.45767 15.7115 6.3192C15.6577 6.99228 15.4769 7.63459 15.1808 8.25382C14.8923 8.86151 14.5192 9.39997 14.05 9.8692C13.4115 10.5038 12.6731 11.0038 11.8038 11.2654C11.3846 11.3923 10.9462 11.4692 10.5154 11.5346C10.2423 11.5769 9.96539 11.5615 9.68846 11.5807C9.13846 11.6154 8.59231 11.6731 8.05 11.8038C7.4 11.9615 6.77692 12.1769 6.18077 12.4769C5.58077 12.7769 5.03462 13.1538 4.52308 13.5884C3.62308 14.3615 2.90385 15.2692 2.39231 16.3346C2.05 17.0423 1.78462 17.7807 1.68846 18.5654C1.63077 19.0269 1.60385 19.4923 1.56154 19.9577C1.04231 19.9577 0.526923 19.9577 0.00769231 19.9577C0 19.7 0 19.4423 0 19.1807ZM5.76923 5.80382C5.74615 8.14997 7.66538 9.93459 9.71923 10.0115C12.3192 10.1115 14.0115 8.12305 14.1538 6.19613C14.3577 3.42305 12.2154 1.55767 9.83462 1.59997C7.65385 1.63843 5.76154 3.44613 5.77308 5.79997L5.76923 5.80382Z" fill="currentColor" stroke-opacity="0"/>
<path d="M0 19.9615C0.519231 19.9615 1.03462 19.9615 1.55385 19.9615C1.55385 19.9769 1.55769 19.9885 1.56154 20.0039C1.07308 20.0039 0.588462 20.0039 0.1 20.0039C0.0692308 20.0039 0.0346154 19.9808 0.00384615 19.9654L0 19.9615Z" fill="currentColor" stroke-opacity="0"/>
<path d="M12.2735 19.9577C12.2735 19.9577 12.2811 19.9846 12.285 20H10.7235C10.7235 20 10.7158 19.9731 10.7119 19.9577H10.7196H10.7273C11.2427 19.9577 11.7581 19.9577 12.2735 19.9539V19.9577Z" fill="currentColor" stroke-opacity="0"/>
<path d="M10.7115 19.9577C10.7115 19.9577 10.7192 19.9846 10.723 20H10.6846C10.6846 20 10.7038 19.9731 10.7115 19.9577Z" fill="currentColor" stroke-opacity="0"/>
<path d="M12.2733 19.9577C11.7579 19.9577 11.2425 19.9577 10.7271 19.9615C10.7271 19.3077 10.6771 18.6462 10.7348 17.9962C10.8156 17.0846 11.1541 16.2539 11.7118 15.5231C12.1694 14.9192 12.731 14.4269 13.4156 14.0962C13.9848 13.8231 14.5733 13.6115 15.2194 13.6C15.7618 13.5923 16.3041 13.5692 16.8464 13.5539C16.8694 13.5539 16.8887 13.55 16.9118 13.5462C16.9002 13.5308 16.8925 13.5192 16.881 13.5039C16.3194 12.9462 15.7541 12.3923 15.181 11.8231C15.5348 11.4731 15.8694 11.1385 16.2002 10.8039C16.2925 10.7115 16.3425 10.7769 16.4041 10.8385C16.8156 11.2539 17.231 11.6654 17.6425 12.0769C18.4041 12.8385 19.1694 13.6 19.9425 14.3731C19.9002 14.4192 19.8656 14.4654 19.8271 14.5039C18.6964 15.6346 17.5656 16.7615 16.4387 17.8962C16.3194 18.0154 16.2541 18.0308 16.131 17.9C15.8618 17.6115 15.5771 17.3308 15.2925 17.0577C15.1887 16.9615 15.1964 16.9039 15.2925 16.8077C15.8541 16.2577 16.4041 15.7039 16.9964 15.1154C16.531 15.1154 16.1041 15.1115 15.681 15.1154C14.6464 15.1269 13.7694 15.5077 13.0925 16.2885C12.681 16.7615 12.3964 17.3154 12.3271 17.9539C12.2887 18.3231 12.2925 18.7 12.281 19.0731C12.2733 19.3654 12.2733 19.6577 12.2733 19.95V19.9577Z" fill="currentColor" stroke-opacity="0"/>
<path d="M16.8803 13.5077C16.8803 13.5077 16.8996 13.5346 16.9111 13.55C16.888 13.55 16.8688 13.5538 16.8457 13.5577C16.8572 13.5423 16.8688 13.5231 16.8803 13.5077Z" fill="currentColor" stroke-opacity="0"/>
</g>
<defs>
<clipPath id="clip0_98731_35880">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>





    </Svg>
};

export default IconReferralSidebar;